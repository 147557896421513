body::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh; 
    background-image: linear-gradient(to bottom, transparent 90%, rgba(255, 255, 255, 0.2) 95%, rgba(255, 255, 255, 0.9));
    z-index: 10;
    pointer-events: none; 
}

body{
    color: #333333;
}

.introcontainer {
    background: 
        linear-gradient(to bottom, transparent 60%, white 70%, white),
        radial-gradient(circle at 60% 25%, rgb(253, 253, 238), transparent 25%, transparent),
        radial-gradient(circle at 5% 50%, rgb(234, 234, 255), transparent 20%, rgb(234, 246, 250))
        ;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 200vh;
    width: 100%;
    flex-direction: column;
}

p {
    font-size: 1.5em;
    font-weight: 300;
}

h1 {
    font-size: 28px;
    font-family: 'Inter';
    font-weight: 400;
}

h2 {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 20px;
}

h3 {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 2em;
    margin-bottom: 20px;
}

.quote {
    font-size: 20px;
    font-family: 'Times New Roman';
    font-weight: 400;
    margin: 40px;
    font-style: italic;
    color:rgba(0, 0, 0, 0.5);
}

ul {
    list-style-type: none;
    margin-left: 40px;
}

li {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 1.5em;
    margin-bottom: 10px;
}

ul li {
    position: relative;
    margin-bottom: 10px; 
}
ul li::before {
    content: '•'; 
    color: #0C6863; 
    position: absolute;
    left: -20px; 
    top: 0; 
    font-size: 20px; 
}

.fleet-content {
    display: flex;
    flex-direction: column;
    background-color: #323232;
    width: 100%; 
    z-index: 2; 
    max-width: 1000px; 
    padding: 40px;
}

.fleet-content p{
    color: rgb(211, 211, 211);
}

.fleet-content li{
    color: rgb(211, 211, 211);
}

.sectionTitle {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 2.8em;
    margin-bottom: 25px;
    margin-top: 50px;
    color: #3e4342;
    text-shadow: 0px 0px 2px #0C6863;
}

.fleetSectionTitle {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 2.5em;
    margin-bottom: 25px;
    margin-top: 50px;
    color: #FFD645;
    text-shadow: 2px 2px 2px 'black';
}

.cat {
    font-size: 1.2em;
    font-family: 'Inter';
    font-weight: 500;
}

.scroll-up {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-family: 'Inter';
    font-weight: 600;
    box-shadow: 2px 2px 10px rgb(0,0,0,0.5);
    z-index: 5;
    border-width: 0;
}

.go-back {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-family: 'Inter';
    font-weight: 600;
    box-shadow: 2px 2px 10px rgb(0,0,0,0.5);
    z-index: 5;
}

.trackr-section {
    background-image: url("../public/img/pics/polygon.png");
    background-color: 'white';
    background-size: 100% 100vh;
    height: 100vh;
    width: 100vw;
    background-repeat:no-repeat;
    background-position:center;
    transition: opacity 0.5s ease;
    opacity: 1;
}

.trackr-section-exit {
    opacity: 0;
    transition: opacity 1s ease;
}

@media screen and (min-width: 1000px) {
    .content {
        padding: 40px; 
    }
    .profile {
        flex-direction: row;
        justify-content: flex-start;
        text-align: left;
    }
    .text-container {
        padding-top: 40px;
        padding-left: 40px;
        padding-right: 20px;
    }
    .introcontainer h1{
        font-size: 2.8em;
    }
    .flicking .card {
        width: 600px;
    }
}

@media screen and (max-width: 999px) {
    .content {
        padding: 20px; 
    }
    .profile {
        flex-direction: column;
        justify-content: center;
    }
    .introcontainer h1{
        font-size: 2em;
    }
    .flicking .card {
        width: 60vw;
    }
}

.content {
    border-radius: 40px; 
    width: '100%'; 
    margin-top: -20px;
    box-shadow: 0px -8px 30px rgb(0,0,0,0.2);
}

.flicking .cardprojects {
    width: 60vw;
    height: 70vh;
    background-color: transparent;
    border-radius: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5%;
    flex-direction: column;
    text-align: center;
}

.flicking .card {
    margin: 20px;
    background-color: white;
    border-radius: 5vw;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5%;
}

.flicking .card p{
    color: #323232;
}

.flicking .phonecard {
    width: 65%;
    height: auto;
    margin: 20px;
    max-width: 350px;
}

.card-wrapper {
    margin: 0 20px;
    max-width: 1000px;
    height: auto;
    margin: 0 -20px;
}

.circle-container {
    flex: 1;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.circle-container p{
    font-size : 1.2em;
}

.profile {
    display: flex;
    align-items: center;
    margin: 5px;
}

.circle {
    height: 20vw;
    width: 20vw;
    max-width: 150px;
    max-height: 150px;
    border-radius: 50%;
    border: 1px solid black;
    overflow:hidden;
}

.text-container {
    flex: 2; /* 2/3 of the space */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.text-container p{
    font-size : 1.2em;
}

/* ANIMATIONS */

.slide-in {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.slide-in.active {
    opacity: 1;
    transform: translateY(0);
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%; 
    z-index: 100; 
  }

  .sticky-bottom {
    position: absolute;
    bottom: 0; 
    width: 100%; 
  }

  .cache-container {
    position: relative;
    background-color: 'white';
  }
  

/* for projects and overviews */

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
}

html {
    font-size: 62.5%;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 4;
    pointer-events: none;
}

.cube-wrapper { 
    perspective: 100rem;
}

.cube {
    width: 27rem;
    height: 55rem;
    border-radius: 5rem;
    transform-style: preserve-3D;
    transform: rotateY(-30deg) rotateZ(5deg) scale(0.5) translateX(20vw) translateY(5rem);
}

.front-side,
.back-side {
    width: 100%;
    height: 100%;
    border-radius: 5rem;
    transform: translateZ(1rem);
    position: absolute;
    transform-style: preserve-3d;
}

.front-side{
    transform: translateZ(1rem);
}

.front-side img,
.back-side img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.back-side {
    transform: translateZ(-1rem);
}

.front-side::before {
    width: 100%;
    content: "";
    height: 2rem;
    position: absolute;
    top: 0;
    left:0;
    transform: rotateX(-90deg);
    transform-origin: top;
}

.back-side::before {
    width: 2rem;
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    right:0;
    transform: rotateY(90deg) translateZ(-0.5rem) scale(0.90);
    transform-origin: right;
    background-color: rgb(71, 71, 71);
}

.front-side::after {
    bottom: 0;
    left:0;
    transform: rotateX(90deg);
    transform-origin: bottom;
}

@media screen and (min-width: 1000px) {
    .section-container{
        width: 100%;
    }
    .section-container p{
        font-size: 2.5em;
    }
    .overview-content {
        width: 100%;
    }
    .row-to-column {
        flex-direction: row;
    }
}
@media screen and (max-width: 999px) {
    .section-container{
        width: 70%;
    }
    .section-container p{
        font-size: 1.5em;
    }
    .overview-content {
        width: 60%;
    }
    .row-to-column {
        flex-direction: column;
    }
}

.section-container{
    width: 100%;
    max-width: 600px;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 30px;
    overflow: hidden;
    transform:  scale(0.5);
}


.section-container h1{
    font-size: 4rem;
    font-weight: 600;
}

.fleet-section-container{
    width: 100%;
    max-width: 600px;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 30px;
    overflow: hidden;
}

.overview-content {
    flex:2; 
    display: flex; 
    flex-direction: column;
    justify-content: space-between; 
}

.row-to-column {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.full-screen-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(45, 45, 45, 0.95); 
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; 
}

.full-screen-menu ul {
    list-style: none;
    text-align: center;
    font-family: 'Inter';
}


.full-screen-menu ul, .full-screen-menu li::before {
    content: ''; 
    font-size: 20px; 
}

.contact-back { 
    width:100vw;
    height: 100vh; 
    justify-content:center; 
    align-items:center; 
    display:flex;
    background-image: url('../public/img/pics/seoul.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
}

.contact-section {
    padding: 20px;
    background-color: rgb(0, 0, 0, 0.5);
    text-align: center;
    width: 80%;
    max-width: 1000px;
  }

  .contact-section p {
    color: white;
    font-size: 1.5em;
    font-weight: 300;
    margin: 20px;
  }

    .contact-section p {
    color: white;
    font-size: 1.5em;
    font-weight: 300;
    margin: 20px;
  }

  .contact-section h2 {
    color: white;
    font-size: 2em;
    font-weight: 400;
    margin: 20px;
  }
  
.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-info p {
    color: white;
    font-size: 1.5em;
    font-weight: 300;
    margin: 20px;
  }

  .contact-form input,
  .contact-form textarea {
    width: 80%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .contact-form button {
    padding: 10px 20px;
    background-color: transparent; 
    color: white;
    border: none;
    border-radius: 20px;
    border-width: 3;
    border-color: white;
    border-style: solid;
    cursor: pointer;
    margin-top: 10px;
  }
